import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Breadcrumb } from "antd";
import navigationConfig from "configs/NavigationConfig";
import IntlMessage from "components/util-components/IntlMessage";
import { t } from "i18next";

let breadcrumbData = {
  "/app": t("home"),
  "/app/pages": " ",
  //   "/app/pages/dashboard": t("Dashboard"),
  //   "/app/pages/user-list": t("user-list"),
  //   "/app/pages/request-service-list": t("request-service-list"),
  //   "/app/pages/vehicle": t("vehicle"),
  //   "/app/pages/driver": t("driver"),
  //   "/app/pages/hospitals": t("hospitals"),
};

navigationConfig.forEach((elm, i) => {
  const assignBreadcrumb = (obj) => {
    breadcrumbData[obj.path] = t(obj.title); // Store the title as a translation key
  };
  assignBreadcrumb(elm);
  if (elm.submenu) {
    elm.submenu.forEach((elm) => {
      assignBreadcrumb(elm);
      if (elm.submenu) {
        elm.submenu.forEach((elm) => {
          assignBreadcrumb(elm);
        });
      }
    });
  }
});

const BreadcrumbRoute = withRouter((props) => {
  const { location } = props;
  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const buildBreadcrumb = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    return (
      <Breadcrumb.Item key={url}>
        <Link to={url}>
          <IntlMessage id={breadcrumbData[url] || url} />
        </Link>
      </Breadcrumb.Item>
    );
  });

  return <Breadcrumb>{buildBreadcrumb}</Breadcrumb>;
});

export class AppBreadcrumb extends Component {
  render() {
    return <BreadcrumbRoute />;
  }
}

export default AppBreadcrumb;
